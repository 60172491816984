
.commonRoundedInputs  > .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
}

.commonRoundedInputs > .MuiInputLabel-outlined {
  transform: translate(14px, 14px) scale(1) !important;
 }

.cursorPoint{
  cursor: pointer !important;
}
.margin-icon{
  margin-top: -10px !important;
}