
.reject-meesage-warning-part {
    fill:  #ff0000 !important;
    background-color: white !important;
    border-radius: 50px !important;
    padding: 17px !important;
    font-size: 65px !important;
    width:100px !important;
    height:100px !important
}
.actionButtonBottom{
    background-color:green !important;
    background: white !important;
    color:black !important;
    font-weight: 600;
    border:2px solid rgba(224, 224, 224, 1) !important;
}
.hoverRejectPart{
    cursor: pointer !important;
 }
 .fontBottom{
     font-family: Nunito !important;
 }
 .fontBottomHeader{
     font-size: 12px !important;
     font-family: Nunito !important;
     font-weight: 700 !important;
     opacity: 0.8 !important;
 }
 .fontBottomBold{
     font-weight: 700 !important;
     font-family: Nunito !important;
     font-size: 12px !important;
     color:#444 !important
 }
 .fontTop{
     font-family: Nunito !important;
     font-weight: 700 !important;
     color: #1A9FE0 !important;
 }
 .blueLabelTableSpecialPrice {
    color: #133f8a !important;
    font-weight: 900 !important;
    font-size: 12px !important;
    font-family: Nunito !important;
    padding: 0px !important;
}
.thSpecialPrice{
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
    display: table-cell;
    padding: 16px;
    line-height: 1rem !important;
    font-size: 0.75rem !important;
    font-family: 'Nunito', sans-serif !important;
    text-align: left;
}
.enquiryPartsSpecialPrice {
    background-color: #ffffff;
    min-height: 25rem;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
    /* max-height: 30rem; */
}
.customTableContainerSpecialPrice{
    width: 100%;
    overflow-x: auto;
}
.tableRootSpecialPrice{
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
}
.tableHeaSpecialPrice{
    display: table-header-group;
}
.greyLabelTableSpecialPrice {
    font-size: 9px !important;
    color: #444444 !important;
    font-weight: 800;
    font-family: Nunito !important;
}
.tableRowSpecialPrice{
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
}
.tdchildSpecialPrice{
    font-family: 'Nunito', sans-serif !important;
    font-size: 0.75rem !important;
    font-weight: 400;
    line-height: 1.43;
    padding: 16px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
    display: table-cell;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;
    text-align: left;
}
.dealer-table-contentSpecialPrice{
    color: #666666;
    opacity: 1;
    font-size: 0.75rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}
.requestedPriceColor{
    color:#ec4242
}
.fontCommonSpecialPrice{
    font-size: 12px !important;
    font-family: Nunito !important;
}
.marginBottomViewSpecialPrice{
    margin-bottom: 20px !important;
}
.borderBottomViewPrice{
border-top: 1px solid #dfe1e5 !important;
}
.commonRoundedInputs-dropdown-viewSpecialPrice .MuiOutlinedInput-root {
    border-top-left-radius: 2.5rem !important;
    border-bottom-left-radius: 2.5rem !important;
    background-color: none !important;
    font-size: 12px !important;
    font-family: Nunito !important;
    font-weight: 600 !important;
    border-color: rgba(19, 63, 138, 0.15) !important;
}
.heightInputSpecialPrice{
   height: 14px !important;
}
.borderRadiusText{
    border-radius: 0px 25px 25px 0px !important;
}
.errorPrice{
    color:red !important;
    font-family: Nunito !important;
}
 #div1::-webkit-scrollbar{
    width: 10px !important;
    height: 10px !important;
    background-color: #F5F5F5;
    overflow-x: hidden !important;
    color:red;
    background-color: red important !important;
    overflow: hidden !important;
   
}
 #div1::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    box-shadow: 0 0 0px rgba(189, 182, 182, 0.445);
    -webkit-box-shadow: inset 0 0 0px rgba(189, 182, 182, 0.445);
    background-color: black !important; 
    overflow-y: scroll !important;
}
.overflowPrice{
    overflow: none !important;
    overflow-x: hidden  !important;
    position: relative;
   
}
.splprice ::-webkit-scrollbar{
  overflow: hidden !important;
}
.mltotal{
    margin-left: 170px !important;
}
.import-excel-color{
  color:#1A9FE0 !important;
  font-weight: bold !important;
  /* padding-left: 110px !important; */
}
.import-excel-error{
    color:red !important
}
.padding-excel{
    padding-left: 150px !important;
    position: relative !important;
}
.backgroundGrey .MuiBadge-colorError{
     background-color: grey !important;
}
.backgroundRed .MuiBadge-colorError{
    background-color: #f44336 !important;
}
.backgroundNone .MuiBadge-colorError{
    background-color: transparent !important;
}
.margin_left{
  padding-left: 50px !important;

}
.hover-cursor{
    cursor: pointer !important;
}
.marign-left-offered{
    margin-left: 120px !important;
}

.margin-excel{
    margin-left: -120px !important;
}
.display{
    display: none !important;
}
.height-button{
    height: fit-content !important;
}
.margin-bottom-spl{
    margin-bottom: 30px !important;
}
.margin-bottom-card{
margin-bottom: 30px !important;
}
.green-legend{
    height: 12px !important;
    width: 12px !important;
    background-color: #00FF20 !important;
    border-radius: 50% !important;
    display: inline-block !important;
    margin-left: 1px !important;
    margin-top: 2px !important;
    margin-left: 1px;
}
.blue-legend{
    height: 12px !important;
    width: 12px !important;
    background-color: #1A9FE0 !important;
    border-radius: 50% !important;
    display: inline-block !important;
    margin-left: 1px !important;
    margin-top: 2px !important;
    margin-left: 1px;
}
.red-legend{
    height: 12px !important;
    width: 12px !important;
    background-color: red !important;
    border-radius: 50% !important;
    display: inline-block !important;
    margin-left: 1px !important;
    margin-top: 2px !important;
    margin-left: 1px;
}
.green-legend-margin{
    margin-top: 2px !important;
    padding-right: 10px !important;
}

.padding-zero{
    padding:0px !important;
    padding-right: 8px  !important;
  
}
.padding-left-cancel{
padding-left: 1px !important;
padding-bottom: 1px !important;
}
.cancelBotton .MuiIconButton-root:hover {
    background-color:white !important;
}
.cancelBottonClicked {
    background-color: white !important;
}
.margin-bottom{
    margin-bottom: 200px !important;
}

.offered-total-margin{
    margin-right:180px !important;
    min-height: 0px !important;
}
.total-margin{
    margin-right: 40px !important;
}
.width-spl{
    min-width: 60px !important;
}
.font-style-color-header-status{
    font-style:  13px !important;
    font-family: Nunito !important;
}
.min-height-table{
    min-height: 400px !important;
    height: 400px !important;
}