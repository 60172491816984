.breadcrumb-text-order-history {
    font-weight: 700 !important;
    font-family: Nunito !important;
    font-size: 11px !important;
    color: #999999 !important;
}

.breadcrumb-text-delivery-details {
    font-weight: 700 !important;
    font-family: Nunito !important;
    font-size: 11px !important;
    color: #444444 !important;
}

.text-blue-underline {
    color: #1A9FE0 !important;
    font-weight: 600 !important;
    text-decoration: underline;
    cursor: pointer;
}
.enquiryViewDelivery{
    color: #1A9FE0;
    cursor:pointer
} 