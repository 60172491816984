.subTitleDeliveryView {
    color: #666666 !important;
    font-family: Nunito !important;
    font-weight: 700 !important;
    /* font-size: 0.70rem; */
}

.subTitleValueDeliveryView {
    color: #666666;
    font-family: Nunito !important;  
    font-weight: 600;
    float: right;
}
   
    /* font-size: 0.6875rem; */