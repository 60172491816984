

.typeOfCustomer{
    margin-right: 10px
}
.width{
    width:600px !important
}
.margin-left{
    margin-left: 30px !important;
}