.returnRequest{
    font-weight: 900;
    font-family: Nunito;
    font-size: 36px;
    color: #000000;
}
.newComment{
    background-color:green;
    color: white;
    font-size: 8px !important;
    font-family: Nunito !important;
    border-radius: 25px  !important;
    padding: 4px  12px 2px 12px !important;
}
.widthSpecialPrcie .MuiDialog-paperWidthMd{
    max-width: 800px !important;
}
.colorBlackPriceRequest{
    color:black !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}
