/* .Dashboard {
    background-color: #ffffff;
    min-height: 20rem;
} */

.bodyContent {
    color: #666666 !important;
    font-weight: 600 !important;
    font-size: 0.75rem !important;
    font-family: Nunito !important;
}

.tableHead {
    color: #133F8A;
    font-weight: 900;
    font-size: 0.75rem;
    font-family: Nunito;
    padding-top: 0px !important;
}

.MuiTableCell-head {
    line-height: 1rem !important;
}

.vat {
    color: #444444;
    font-size: 0.613rem !important;
    font-family: Nunito !important;
    font-weight: 800 !important;
}

.create-order-enquiry-button {
    border-radius: 25px !important;
    background-color: #1A9FE0 !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    padding-left: 32px !important;
    padding-right: 32px !important;
    height: 2.3rem;
    font-weight: 500 !important;
    font-family: Nunito !important;
    margin-bottom: 2px !important;
}


.legendItemCreateEnquiry {
    font-size: 0.75rem;
    color: #666666;
    font-weight: 500;
    font-family: Nunito !important;
}

.legendItemCreateEnquiry .legendItemLabel {
    margin-left: 4px;
}

.legendItemCreateEnquiry .specialprice {
    height: 12px;
    width: 12px;
    background-color: #1A9FE0;
    border-radius: 50%;
    display: inline-block;
}