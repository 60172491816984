@import url('https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i');


body {
    margin: 0;
    font-family: 'Nunito', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #666666 !important;
    font-size: .8125rem;
}

.rounded-button-dealer-search {
    /* background-color: #f2f2f2 !important; */
    border-radius: 25px !important;
    text-transform: capitalize !important;
    font-size: 0.75rem !important;
    font-weight: 500 !important;
    font-family: Nunito !important;
    width: 300px;
    /* color:#f2f2f2 !important; */
}

.disabled-link {
    /* position: absolute; */
    bottom: 0;
    text-decoration: underline;
    color: #99d6f5;
    font-weight: 700;
}

.actionButtonForOrderLog {
    background-color: #e88b00 !important;
    color: white !important;
    height: 35px !important;
    text-align: center !important;
    font-family: Nunito !important;
    font-weight: 800 !important;
    border-radius: 2.5rem !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    height: 2.5rem !important;
    font-size: 11px !important;
}

.load-from-fav-order-log {
    font-family: Nunito !important;
    font-weight: 600 !important;
}

.order-logs-no {
    color: #444444 !important;
    font-size: 13px !important;
    font-family: Nunito !important;
    font-weight: 700 !important;
    padding: 10px 15px;
    background-color: rgba(185, 210, 252, 0.3) !important;
}

.TandC-history-icon {
    color: #1A9FE0;
}

.TandC-font-style-no {
    color: #444444 !important;
    font-size: 13px !important;
    font-family: Nunito !important;
    font-weight: 700 !important;
    /* padding: 10px 15px; */
    background-color: rgba(185, 210, 252, 0.3) !important;
}

.headingColourChooseCustomer {
    color: #444444;
    text-transform: capitalize !important;
    font-size: 1rem !important;
    font-weight: 800 !important;
    font-family: Nunito !important;
    width: 300px;
}

.submit-button-chooseCustomer {
    border-radius: 25px !important;
    background-color: #1A9FE0 !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    padding-left: 28px !important;
    padding-right: 28px !important;
    height: 2.2rem;
    font-weight: 600 !important;
    font-family: Nunito !important;
    text-align: center;
    margin-right: 24px;
    width: 120,
}

.goToLogin {
    background-color: #EAEAEA !important;
    width: 120 !important;
    font-weight: 600 !important;
    font-family: Nunito !important;
    font-size: 0.688rem !important;
    color: #666666 !important;
    border-radius: 25px !important;
    height: 35 !important;
    text-align: center !important;
    margin-right: 24 !important;
}

.subheadingChooseCustomer {
    color: #444444;
    text-transform: capitalize !important;
    font-size: 0.85rem !important;
    font-weight: 800 !important;
    font-family: Nunito !important;
    width: 300px;
}

.fontColourChooseCustomer {
    color: #444444;
    text-transform: capitalize !important;
    font-size: 0.75rem !important;
    font-weight: 600 !important;
    font-family: Nunito !important;
    width: 300px;
}

.subheadingfont {
    color: #918c8c;
    text-transform: capitalize !important;
    font-size: 1px !important;
    font-weight: 600 !important;
    font-family: Nunito !important;
    width: 100px;
}

.collapseBgChooseCustomer {
    background-color: #d1ecf9;
}

.MuiFormLabel-root,
.MuiButton-root,
.MuiTypography-root,
.MuiTableCell-root,
.MuiTab-root {
    font-family: 'Nunito', sans-serif !important;
    font-size: .8125rem !important;
}

.text-info-deactivationReason {
    color: #e0591a !important;
    font-size: 12px !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
    text-transform: capitalize !important;
}

.TandC-onAccess-font {
    font-weight: 800;
    color: #1A9FE0;
    ;
}

.MuiFormHelperText-root {
    color: red !important;
}

.fc-myCustomButton-button {
    background: transparent !important;
    color: #1A9FE0 !important;
    font-weight: 700 !important;
    border: none !important;
    cursor: default !important;
}

.fc .fc-button-primary:focus {
    box-shadow: none !important;
}

.display-content {
    display: contents !important;
}

/* .MuiInputBase-input{} */

.MuiFormLabel-root.Mui-focused {
    color: #1A9FE0 !important;
}

.oes-class {
    margin-left: -29px !important;
    margin-top: -25px !important;
}

.MuiButton-root:hover {
    text-decoration: none;
    background: none;
}

.bg-secondary {
    background: #c0c1c84a !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(165, 163, 163, 0.459);
    box-shadow: 0 0 0px rgba(165, 163, 163, 0.459);
    border-radius: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 10px !important;
    height: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: 0 0 0px rgba(189, 182, 182, 0.445);
    -webkit-box-shadow: inset 0 0 0px rgba(189, 182, 182, 0.445);
    background-color: #1A9FE0;
}

.noselecttionZone {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently*/
}

.pointer-events-none {
    pointer-events: none
}

.font-9 {
    font-size: 0.575rem !important;
}

.font-10 {
    font-size: 0.625rem !important;
}

.font-11 {
    font-size: 0.6875rem !important;
}

.font-12 {
    font-size: 0.75rem !important
}

.font-13 {
    font-size: 0.8215rem !important;
}

.font-14 {
    font-size: 0.875rem;
}

.font-16 {
    font-size: 1rem;
}

.font-18 {
    font-size: 1.125rem
}

.font-20 {
    font-size: 1.25rem !important
}

.font-24 {
    font-size: 1.5rem
}

.font-30 {
    font-size: 1.875rem
}

.font-32 {
    font-size: 2rem
}

.font-48 {
    font-size: 3rem;
}

.font-72 {
    font-size: 4.5rem;
}

.ln-24 {
    line-height: 1.5rem;
}

.ln-20 {
    line-height: 1.25rem;
}

.ln-40 {
    line-height: 2.5rem;
}

.ln-52 {
    line-height: 3.25rem;
}

.ln-16 {
    line-height: 1rem;
}

.w-50p {
    width: 50px !important;
}

.w-60p {
    width: 60px !important;
}

.w-100p {
    width: 100px !important;
}

.w-104 {
    width: 104% !important
}

.w-150p {
    width: 150px !important;
}

.w-200p {
    width: 200px !important;
}

.text-red {
    color: #ff0000 !important;
}

.text-orange {
    color: #E88B00 !important;
}

.text-blue {
    color: #1A9FE0 !important;
}

.text-666 {
    color: #666666;
}

.text-444 {
    color: #444444;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.w-19px {
    width: 19px !important;
}

.w-12px {
    width: 12px !important;
}

.vertical-align-top {
    vertical-align: top;
}

.mw-200p {
    min-width: 200px !important;
}

.mw-210p {
    min-width: 210px !important;
}

.mw-230p {
    min-width: 230px !important;
}

.mw-240p {
    min-width: 240px !important;
}

.mw-150p {
    min-width: 150px !important;
}

.mw-100p {
    min-width: 100px !important;
}
.mw-50p {
    min-width: 50px !important;
}

.h-240 {
    height: 240px !important;
}
.max-w-200p {
    max-width: 200px !important;
}
.w-280p {
    width: 280px !important;
}

.MuiPaper-elevation1 {
    border-bottom: none !important;
}

.ml-25 {
    margin-left: 25rem !important;
}

.mw-50p {
    min-width: 50px !important;
}

.h-240 {
    height: 240px !important;
}

.max-w-200p {
    max-width: 200px !important;
}

.w-280p {
    width: 280px !important;
}

.MuiPaper-elevation1 {
    border-bottom: none !important;
}

.ml-25 {
    margin-left: 25rem !important;
}

.hw-64 {
    height: 64px !important;
    width: 64px !important;
}

.h-40 {
    height: 40px;
}

.h-28 {
    height: 28px;
}

.hw-45 {
    height: 45px !important;
    width: 45px !important;
}

.text-fff {
    color: #fff;
}

.creditUtilizedToolTip {
    color: white !important;
}

.popup-modal-content {
    width: 300px !important;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.popup-title {
    height: 40px;
    position: absolute;
    width: 100%;
    left: 0px;
    background-color: whitesmoke;
    top: 0px;
    text-align: center;
    padding-top: 5px;
    color: black;
}

.popup-content {
    margin-top: 40px;
    padding-top: 10px;
    width: 100%;
    text-align: center;
}

.opentask .MuiTab-wrapper {
    font-weight: 800 !important;
    font-size: 14px !important;
    font-family: 'Nunito';
}

.popup-modal {
    height: 70px;
}

.MuiAccordionDetails-root {
    display: contents !important;
}

.left-margin {
    margin-left: 10px;
}

.toast-wrapper {
    position: fixed;
    top: 60px;
    right: 30px;
    z-index: 100 !important;
}

.toast {
    opacity: 1 !important;
    width: 350px;
}

.text-bold-termsandconditions {
    color: '#616161';
    font-size: '0.875rem';
    font-family: 'Nunito';
    font-weight: 700;
    text-transform: 'inherit';
}

.toast-header {
    color: dodgerblue !important;
}

.card-bottom-list {
    max-height: 303px;
}

.h-300 {
    max-height: 350px !important;
}

.bg-login-image {
    height: 200px;
    margin-bottom: 20px;
}

.bg-gradient-primary-green {
    background-image: linear-gradient(180deg, #2962ff 10%, #00c6b6 100%);
    background-size: cover;
}

.icon-green {
    color: seagreen;
}

.bg-custom-dark {
    background-color: #ffffff;
}

.dark-breadcrumb {
    background-color: #2962ff !important;
    margin-top: 15px !important;
}

li a {
    color: cadetblue;
}

.cadet {
    color: cadetblue;
}

.text-green {
    color: cadetblue !important;
    font-family: sans-serif !important;
}

.toggle-area {
    display: none;
}

.collapsed {
    display: block;
}

.toggle-button {
    background-color: #2962ff !important;
    border-color: #2962ff !important;
    width: 40px;
    color: seagreen !important;
}

.min-logo-7-11 {
    display: block;
    text-align: left;
    height: 50%;
    width: 60px;
}

.minisidebar .minisidebar-brand {
    text-decoration: none;
    font-size: 20px;
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    background-color: var(--white);
}

.minisidebar .minisidebar-brand .minisidebar-brand-icon i {
    font-size: 2rem;
}

input[disabled] {
    background-color: rgba(255, 255, 255, 0.04);
}

.error-border-input {
    border: 2px solid red !important;
}

.center-alignment {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    align-items: center !important;
    justify-content: center !important;
}

.h-45 {
    height: 45px !important;
}

.remove-cursor-icon {
    cursor: not-allowed !important;
    cursor: none !important;
    pointer-events: none !important
}

/*Error Pages CSS*/

.expired-error {
    font-family: Nunito !important;
    color: #333333 !important;
    font-size: 150px !important;
    font-weight: 900 !important;
    text-shadow: 0 1px 0 #000000, 0 2px 0 #000000, 0 3px 0 #000000, 0 4px 0 #000000, 0 5px 0 #000000;
}

.user-already-registered-error {
    font-family: Nunito !important;
    color: #333333 !important;
    font-size: 150px !important;
    font-weight: 900 !important;
}

.expired-link-message {
    font-family: Nunito !important;
    color: #000000 !important;
    font-size: 25px !important;
    font-weight: 900 !important;
}

.expired-link-desc {
    font-family: Nunito !important;
    color: #000000 !important;
    font-size: 15px !important;
    font-weight: 700 !important;
}

.go-to-home-button {
    font-family: Nunito !important;
    text-transform: inherit !important;
    font-weight: 600 !important;
}

.click-entire-row {
    cursor: pointer;
}

@media (min-width: 384px) {
    .logo-7-11 {
        height: 28px;
        display: block;
        text-align: center;
        padding: 7px;
    }

    .logo-brand-text {
        font-size: 16px;
        font-weight: bold;
    }
}

@media (min-width: 576px) {
    .logo-7-11 {
        height: 30px;
        display: block;
        text-align: center;
        padding: 7px;
    }

    .logo-brand-text {
        font-size: 20px;
        font-weight: bold;
    }
}

@media (min-width: 768px) {
    .logo-7-11 {
        height: 41px;
        display: block;
        text-align: center;
        padding: 7px;
    }

    .logo-brand-text {
        font-size: 28px;
        font-weight: bold;
    }
}

.scrollTop {
    position: fixed;
    width: 100%;
    bottom: 20px;
    height: 20px;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
}

.scrollTop:hover {
    opacity: 1;
}

.ScrollTopIcon {
    display: block;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.5;
    }
}

@media (max-width: 380px) {
    .collapsed {
        display: none !important;
    }

    .toggle-area {
        position: fixed;
        bottom: 2%;
        left: 2%;
        z-index: 200 !important;
        display: block;
    }
}

.pointer-none {
    pointer-events: none !important;
}

.flexone {
    flex: 1;
}

.row-space-between {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.row-space-start {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start !important;
}

.row-space-end {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}


/*common css*/


/*button theme*/

.MuiButton-root {
    padding: 6px 24px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important
}

.MuiButton-contained {
    box-shadow: none !important;
    text-transform: capitalize !important;
}

.MuiButton-containedPrimary {
    background-color: #1a9fe0 !important;
}

.MuiButton-containedPrimary:hover {
    background-color: #1297d8 !important;
}

.MuiButton-containedPrimary.Mui-disabled {
    color: #fff !important;
    opacity: 0.65;
}

.btn-link,
.btn-link:hover {
    padding: 0 !important;
    background-color: transparent !important;
}

.btn-link .MuiTouchRipple-root {
    display: none;
}


/*dropdown change*/

.MuiMenuItem-root,
.MuiInputBase-root {
    font-size: 0.8125rem !important;
    font-family: 'Nunito', sans-serif !important;
}

.MuiSelect-select:focus {
    background-color: transparent !important;
    border-radius: 500px !important;
}


/*mat tab css*/

.MuiTab-root {
    text-transform: capitalize !important;
    font-weight: 500 !important;
}

.MuiTab-textColorPrimary {
    color: #666 !important;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: #1A9FE0 !important;
    font-weight: 900 !important;
}

.MuiTabs-indicator {
    background-color: #1A9FE0 !important;
    height: 5px !important;
    border-radius: 10px;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #1A9FE0 !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: #1A9FE0 !important;
}

.small-check .MuiSvgIcon-fontSizeSmall {
    font-size: 14px !important;
}

.cal-icon .MuiIconButton-root {
    padding: 0 !important;
}


/*table*/

.MuiTableCell-root {
    font-size: 0.75rem !important;
    /* white-space: nowrap; */
}


.enquiryDetails .MuiTableCell-root {
    white-space: nowrap;
}

.enquiryDetails .MuiCheckbox-root {
    padding: 0px !important;
}

.enquiryDetails .MuiTableCell-root.font-10 {
    white-space: nowrap;
    font-size: 0.625rem !important;
}

.bg-transparent {
    background-color: transparent !important;
}


/*left nav*/

.left-nav .MuiSvgIcon-root {
    height: 1.3rem;
    width: 1.3rem;
}

.MuiListItem-button:hover {
    background-color: transparent !important;
}

.MuiListItem-button:hover .MuiTypography-body1 {
    font-weight: 600;
}

.MuiListItem-button:hover::before,
.MuiListItem-button.active-menu::before {
    content: " ";
    position: absolute;
    background-color: #666;
    width: 6px;
    height: 100%;
    left: -16px
        /*coz of padding to parent*/
    ;
}

.left-nav .MuiTouchRipple-root {
    display: none;
}

.MuiListItem-button.active-menu,
.MuiListItem-button.active-menu .MuiListItemIcon-root {
    color: #1A9FE0 !important;
}

.MuiListItem-button.active-menu::before {
    background-color: #1A9FE0;
}

.MuiListItem-button.active-menu .MuiTypography-body1 {
    font-weight: 800;
}


/*md dialog size*/

.MuiDialog-paperWidthMd {
    max-width: 1050px !important;
}

.MuiDialog-paper {
    overflow-x: hidden;
}

/*utility*/

.mh-300p {
    min-height: 300px;
}

.over-flow-auto {
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

.page-title,
.page-sub-title {
    font-weight: 800;
    font-size: 1.5rem;
    color: #000000;
}

.page-Tier-title {
    font-size: 0.89rem;
    color: #1A9FE0 !important
}

.page-Tier-Brand-title {
    font-size: 0.89rem;
    color: #94e01a !important
}

.page-title,
.page-sub-title {
    font-weight: 800;
    font-size: 1.8rem;
    color: #000000;
}

.message-viewstatus {
    font-size: 0.8rem !important;
    font-family: Nunito !important;
    font-weight: 700 !important;
}

.tdchildCreateEnquiry-specialprice {
    font-family: 'Nunito', sans-serif !important;
    font-size: 0.75rem !important;
    font-weight: 400;
    line-height: 1.43;
    padding: 10px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
    display: table-cell;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;
    text-align: left;
}

.textAreaJsutificationSpecialPrice {
    border-radius: 10px !important;
    border-color: rgb(206, 204, 204) !important;
    font-size: 15px !important;
    width: 100% !important;
    height: 100px !important;
    resize: none;
    background: rgb(252, 246, 246) !important;
    /* position: relative !important; */
}

.message-viewstatus-text {
    color: #1a9fe0;
    font-size: 0.8rem !important;
    font-family: Nunito !important;
    font-weight: 700 !important;
}

.page-subheading {
    font-weight: 800;
    font-size: 1.5rem;
    color: #000000;
}

.brandLogo-viewspecial-price {
    border-radius: 100px;
    height: auto;
    width: 80%;
}

.page-sub-title-set-target {
    font-weight: 800;
    font-size: 0.75rem;
    color: #000000;
}

.heading-text-special-text {
    font-family: Nunito !important;
    font-size: 1.1em !important;
    font-weight: 900 !important;
    color: #7093ec;
}

.page-sub-title {
    font-size: 0.875rem;
    margin-bottom: 6px;
}

.MuiFormControlLabel-label {
    font-family: Nunito !important;
    font-size: 0.8em !important;
    font-weight: 800 !important;
    color: #141414;
}

.MuiListItem-gutters {
    padding-left: 12px;
    padding-right: 12px;
}

.date-picker-50 .DateInput {
    width: 46% !important;
}

.icon-color {
    color: #15a038;
}

.icon-color-red {
    color: #ff0000;
}

.font-color-red {
    font-family: Nunito !important;
    font-size: .8125rem !important;
    font-weight: 800 !important;
    color: #ff0000;
}

.copyright {
    position: absolute;
    bottom: 12px;
}


/* theme*/

.bg-primary {
    background-color: #1A9FE0 !important;
}

.MuiPickersDay-daySelected {
    background-color: #1A9FE0 !important;
}

.MuiAlert-root {
    border-radius: 100px !important;
}

.MuiBreadcrumbs-separator {
    margin-left: 3px !important;
    margin-right: 3px !important;
}

@media (max-height: 790px) {
    .copyright {
        position: relative;
        bottom: auto
    }
}


/* Medium large devices (1200px to 1399 px)*/

@media (min-width: 1200px) and (max-width: 1424px) {
    .col-ml-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-ml-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-ml-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-ml-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-ml-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-ml-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-ml-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-ml-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-ml-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-ml-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-ml-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .col-ml-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .MuiButton-root {
        padding: 6px 18px !important;
    }
}

.lostSaleReport .MuiOutlinedInput-input {
    padding: 12.5px 14px;
}

.dealer-custom-dropdown {
    font-family: "Nunito" !important;
    height: "200px" !important;
    overflow-y: "scroll" !important;
}

.searchDealer {
    background-color: #FAFAFA !important;
    font-size: 0.725rem !important;
    font-family: Nunito !important;
    color: #999999 !important;
    font-weight: 600 !important;
}

.approve-warning {
    fill: #1a9fe0 !important;
    background-color: white !important;
    border-radius: 50px !important;
    padding: 1px !important;
    font-size: 50px !important;
}

.searchDealer .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.onboard-footer {
    display: flex !important;
    justify-content: end;
}

.orderLog-footer {
    position: fixed;
}

.tandcHeading-orderlog {
    color: #444444 !important;
    font-size: 13px !important;
    font-family: Nunito !important;
    font-weight: 700 !important;
    padding: 10px 15px;
}

.display-flex {
    display: flex !important;
}

.defaultAddressButton {
    border-radius: 2.5rem !important;
    background-color: #666666 !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    font-family: Nunito !important;
    font-size: 0.625rem;
    min-width: 100%;
    /* height: 30px; */
}

.brandbutton {
    border-radius: 2.5rem !important;
    background-color: #666666 !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    font-family: Nunito !important;
    font-size: 0.625rem;
    min-width: 50%;
    height: 25px;
}

.creditAmountButton {
    border-radius: 2.5rem !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    font-family: Nunito !important;
    font-size: 0.625rem;
    min-width: 50%;
    height: 25px;
    background-color: #eef3f5 !important;
    color: #1A9FE0 !important;
}

.tandcHeading-orderhistory {
    font-size: 0.90rem;
    color: #666666;
    font-weight: 700;
    font-family: Nunito !important;
}

.tandcBody-orderhistory {
    font-size: 0.85rem;
    color: #666666;
    font-weight: 500;
    font-family: Nunito !important;
}

.title-orderhistory {
    color: red !important;
    font-family: Nunito !important;
    font-weight: 800 !important;
    font-size: 0.80rem !important;
}

.support-radio-button {
    background-color: #dfecf1 !important;
}

.support-decription {
    border-radius: 10px !important;
    border-color: teal;
    font-size: 15px !important;
    width: 960px;
    height: 120px;
    resize: none;
}

.creditAmountButtonKAM {
    border-radius: 2.5rem !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    font-family: Nunito !important;
    font-size: 0.625rem;
    min-width: 50%;
    height: 25px;
    background-color: #eef3f5 !important;
    color: black !important;
}

.subTitleKamCredit {
    color: #666666;
    font-family: Nunito;
    font-size: 0.79rem;
    font-weight: 700;
}

.underKAMReviewAddressButton {
    border-radius: 2.5rem !important;
    background-color: #f30a0a !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    font-family: Nunito !important;
    font-size: 0.625rem;
    min-width: 100%;
    /* height: 30px; */
}

.defaultStatusButton {
    border-radius: 2.5rem !important;
    background-color: #666666 !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    font-family: Nunito !important;
    font-size: 0.625rem;
    min-width: 100%;
}

.hiddenNo input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.hiddenNo input[type=number] {
    -moz-appearance: textfield;
}

.onshiftoparty-font {
    font-weight: 800;
}

.shift-to-party-page-title {
    font-weight: 800;
    font-size: 1.75rem;
    color: #000000;
}

.shift-to-party-details-page-title {
    font-weight: 800;
    font-size: 1.2rem;
    color: #000000;
}

.newCustomerDialogTitle {
    font-weight: 800;
    font-size: 1.75rem;
    color: #000000;
}

.document-content {
    color: #666666;
    opacity: 1;
    font-size: 0.90rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.document-content-fontcolor {
    color: #8b8888;
    opacity: 1;
    font-size: 0.90rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.quotation-created {
    fill: #07ac07 !important;
    background-color: white !important;
    border-radius: 50px !important;
    padding: 7px !important;
    font-size: 65px !important;
}

.document-sub-content-sub {
    color: #868484;
    opacity: 0.5rem;
    font-size: 0.75rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.document-content-cust {
    color: #666666;
    opacity: 0.5rem;
    font-size: 0.85rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.submit-dialog-content {
    color: #666666;
    opacity: 1;
    font-size: 1.1rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.submit-dialog-content-SAP-Dialog {
    color: #666666;
    opacity: 1;
    font-size: 1rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.submit-quotation-dialog-content {
    color: #2b2a2a;
    opacity: 1;
    font-size: 1.3rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.customerDialog {
    color: #666666;
    opacity: 0.5;
    font-size: 0.90rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.info-sub-header-document {
    color: #1A9FE0;
    font-size: 13px;
    font-family: Nunito !important;
    font-weight: 700 !important;
}

.info-sub-header-document-support {
    color: #1A9FE0;
    opacity: 1;
    font-size: 0.70rem !important;
    font-weight: 600 !important;
    font-family: Nunito !important;
}

.paymentstatusfont {
    color: #4ad812;
}

.paymentstatusfont-payment-due {
    color: #ff0000;
}

.rounded-button-dealer-cancel {
    background-color: #1a9fe0 !important;
    border-radius: 25px !important;
    text-transform: capitalize !important;
    font-size: 0.75rem !important;
    font-weight: 500 !important;
    font-family: Nunito !important;
    color: #f2f2f2 !important;
}

.rounded-button-dealer-add-customer {
    background-color: #1a9fe0 !important;
    border-radius: 25px !important;
    text-transform: capitalize !important;
    font-size: 0.75rem !important;
    font-weight: 500 !important;
    font-family: Nunito !important;
    color: #f2f2f2 !important;
    height: 40px !important;
    width: 150px !important;
}

@media only screen and (min-width: 1366px) and (max-width: 297px) {

    .card-height-kam-filters {
        max-height: 50px;
        min-height: 50px;
        border: none;
        color: yellow;
        background-color: #0cd316;
    }
}

.card-height-kam-filters {
    max-height: 60px;
    min-height: 60px;
    border: none;
}

.info-sub-header {
    color: #1A9FE0;
    font-size: 13px;
    font-family: Nunito !important;
    font-weight: 700 !important;
}

.document-sub-content {
    color: #666666;
    opacity: 1;
    font-size: 0.75rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.document-sub-content-tandc {
    color: #333333;
}

.document-sub-content-dark {
    color: #3a3535;
    opacity: 1;
    font-size: 0.75rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.info-sub-header-tandc {
    color: #1A9FE0;
    font-size: 13px;
    font-family: Nunito !important;
    font-weight: 700 !important;
}

.toast-header-icon-tandc {
    color: #1A9FE0 !important;
}

.subTitle-red {
    color: #e80000;
    font-family: Nunito;
    /* font-size: 0.75rem; */
    font-weight: 700;
}

.subTitle-yellow {
    color: #E88B00;
    font-family: Nunito;
    /* font-size: 0.75rem; */
    font-weight: 700;
}

.subTitle-blue {
    color: #1A9FE0;
    font-family: Nunito;
    /* font-size: 0.75rem; */
    font-weight: 700;
}

.document-sub-content-add {
    color: #666666;
    opacity: 1;
    font-size: 0.72rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.document-sub-content-additional {
    color: #666666;
    opacity: 1;
    font-size: 0.75rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.document-border-style {
    padding: 0,
}

.document-tablecell-data {
    color: #666666;
    opacity: 1;
    font-size: 0.88rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.quotation-icon-font {
    color: #1A9FE0;
}

.textArea-correction1 {
    border-radius: 10px !important;
    border-color: teal;
    font-size: 15px !important;
    width: 500px;
    height: 120px;
    resize: none;
}

.whiteBackdropDialog .MuiBackdrop-root {
    background-color: white !important;
}

.addAuthorised {
    border-radius: 2.5rem !important;
    background-color: #1A9FE0 !important;
    font-weight: 600 !important;
    width: 600px;
    text-transform: inherit !important;
    font-family: Nunito !important;
    font-size: 0.625rem;
    min-height: 35px;
}

.typeDialogParent .MuiBackdrop-root {
    background: white !important;
}

.typeDialogParent1 .MuiDialogContent-root {
    background: white !important;
    padding: 1rem !important;
}

.quotation .MuiDialogContent-root {
    overflow-x: hidden !important;
}

.marginScrollableArea {
    margin-bottom: 100px !important;
}

.yellowActionButton {
    background-color: #e88b00 !important;
    color: white !important;
}

.checkIcon-setTarget {
    fill: #1a9fe0 !important;
    background-color: white !important;
    border-radius: 50px !important;
    padding: 7px !important;
    font-size: 65px !important;
}

.title-set-target-dialog {
    color: #000000;
    font-size: 20px !important;
    border-radius: 1rem !important;
    font-family: Nunito !important;
    font-size: 0.625rem;
}

.rounded-input-dealer-admin,
.rounded-input-dealer .MuiOutlinedInput-root {
    border-radius: 25px !important;
    /* min-width: 200px !important; */

}

.reddot {
    height: 9px;
    width: 9px;
    background-color: #e80000;
    border-radius: 50%;
    display: inline-block;
}

.bluedot {
    height: 9px;
    width: 9px;
    background-color: #1A9FE0;
    ;
    border-radius: 50%;
    display: inline-block;
}

.resetToDefaultActionButton {
    background-color: #1a9fe0 !important;
    color: white !important;
}

.resetToDefaultActionButton {
    background-color: #1a9fe0 !important;
    color: white !important;
}

.redActionButton {
    background-color: #ec4242 !important;
    color: white !important;
}

.checked-cursor {
    cursor: default !important;
}

.blueActionButton {
    background-color: #1a9fe0 !important;
    color: white !important;
}

.Reopned-ActionButton {
    background-color: #ffa500 !important;
    color: white !important;
}

.blueActionButton-superAdmin {
    background-color: #1a9fe0 !important;
    color: white !important;
    width: 130px;
    height: 150 px;
}

.blueActionButtonSave {
    background-color: #1a9fe0 !important;
    color: white !important;
    width: 150px;
}

.greenActionButton {
    background-color: #15a038 !important;
    color: white !important;
}

.disabledButtonCommon {
    opacity: 0.5 !important;
    pointer-events: none !important;
}

.subTitleRetrieveDialogContent {
    color: #666666;
    font-family: Nunito;
    font-size: 0.85rem;
    font-weight: 700;
}

.setTarget-monthly-target {
    color: #ffa500 !important;
    font-family: Nunito;
    font-size: 0.75rem;
    font-weight: 700;
}

.setTarget-quarter-target {
    color: #1A9FE0 !important;
    font-family: Nunito;
    font-size: 0.75rem;
    font-weight: 700;
}

.setTarget-yearly-target {
    color: #0cd316 !important;
    font-family: Nunito;
    font-size: 0.75rem;
    font-weight: 700;
}

.info-sub-title-text {
    color: #888888;
    font-size: 12px;
    font-family: Nunito !important;
    font-weight: 700;
}

.info-sub-title-value-default {
    color: #666666;
    font-family: Nunito;
    font-size: 0.75rem;
    font-weight: 700;
}

.smalldialogadd .MuiPaper-root {
    max-width: 500px;
    min-width: 500px;
}

.file-name-kam {
    color: #1A9FE0 !important;
    opacity: 1;
    font-size: 0.70rem !important;
    font-weight: 600 !important;
    font-family: Nunito !important;
}

.file-close-color {
    color: #1A9FE0 !important;
}

.dollar-kam-dealerList {
    color: #1A9FE0 !important;
}

.file-name-kam {
    color: #1A9FE0 !important;
    opacity: 1;
    font-size: 0.70rem !important;
    font-weight: 600 !important;
    font-family: Nunito !important;
}

.file-close-color {
    color: #1A9FE0 !important;
}

.dollar-kam-dealerList {
    color: #1A9FE0 !important;
}

.dealer-expired-heading {
    color: #071d42 !important;
    font-size: 1.2rem !important;
    font-weight: 800 !important;
    font-family: Nunito !important;
    text-transform: inherit;
}

.status-heading {
    font-family: Nunito !important;
    font-size: 1.1em !important;
    font-weight: 600 !important;
    color: #800080;
}

.disclaimer-notes {
    font-family: Nunito !important;
    font-size: 0.85rem !important;
    font-weight: 700 !important;
    color: red;
}

.live-status {
    font-family: Nunito !important;
    font-size: 0.85rem !important;
    font-weight: 700 !important;
    color: #24d81d !important;
}

.re-order-button {
    color: orange !important;
    font-family: Nunito !important;
    font-weight: 800 !important;
}

.dealer-expired-subheading {
    color: red !important;
    font-size: .892rem !important;
    font-weight: 600 !important;
    font-family: Nunito !important;
    text-transform: inherit;
}

.dealer-expired-subText {
    color: black !important;
    font-size: .892rem !important;
    font-weight: 600 !important;
    font-family: Nunito !important;
    text-transform: inherit;
}

.dealer-table-title-heading {
    color: #1fad0c;
    opacity: 1;
    font-size: 0.80rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.dealer-table-subcontent-reason {
    color: #666666;
    opacity: 1;
    font-size: 0.90rem !important;
    font-weight: 600 !important;
    font-family: Nunito !important;
}

.dealer-table-content-subheading {
    color: #1A9FE0 !important;
    font-size: 0.85rem;
    font-weight: bold;
    text-decoration: underline !important;
}

.dealer-heading-add-info {
    color: #37383a !important;
    font-size: 0.95rem;
    font-weight: bold;
}

.toast-icon {
    color: #1A9FE0 !important;
}

.info-sub-header-text {
    color: #1A9FE0;
    font-size: 13px;
    font-family: Nunito !important;
    font-weight: 700 !important;
}

.dealer-table-content-brand {
    color: #1A9FE0 !important;
    font-size: 0.80rem;
    font-weight: bold;
}

.denied-reason {
    color: #ec0b0b !important;
    font-size: 12px;
    font-family: Nunito !important;
    font-weight: 800 !important;
}

.fontTop-import {
    font-size: 13px !important;
    font-family: Nunito !important;
    font-weight: 700 !important;
    color: #1A9FE0 !important;
}

.row-color-changes {
    background-color: #fab8b8 !important;
}

.forward-to-kam-right-arrow {
    fill: #1A9FE0 !important;
    background-color: white !important;
    border-radius: 50px !important;
    padding: 7px !important;
    font-size: 65px !important;
}

.bottom-subheader {
    color: #444444;
    font-family: Nunito;
    font-size: 13px !important;
    font-weight: 800;
}

.bottom-subheader-Payment-modes {
    color: red;
    font-family: Nunito;
    font-size: 13px !important;
    font-weight: 800;
}

.payment-bottom-subheader-content {
    color: #444444;
    font-family: Nunito;
    font-size: 13px !important;
    font-weight: 1000;
}

.font-text {
    color: #444444;
    font-family: Nunito;
    font-size: 18px !important;
    font-weight: 700;
}

.payment-bottom-subheader-content-additional {
    color: #28e20f;
    font-family: Nunito;
    font-size: 13px !important;
    font-weight: 700;
}

.offline-content {
    color: red;
    font-family: Nunito;
    font-size: 13px !important;
    font-weight: 700 !important;
}

.offline-content-amount {
    color: red;
    font-family: Nunito;
    font-size: 14px !important;
    font-weight: 800 !important;
}

.payment-radiobutton-subheader {
    color: black;
    font-family: Nunito;
    font-size: 18px !important;
    font-weight: 800 !important;
}

.font-style-color {
    color: black;
    font-family: Nunito;
    font-size: 12px !important;
    font-weight: 700 !important;
}

.font-style-color-sp {
    color: red;
    font-family: Nunito;
    font-size: 12px !important;
    font-weight: 700 !important;
}

.special-price-title-desc {
    color: #464545;
    opacity: 1;
    font-size: 0.80rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.font-style-color-header {
    color: black;
    font-family: Nunito;
    font-size: 15px !important;
    font-weight: 700 !important;
}

.blueLabelTable-font {
    color: #133f8a;
    font-family: Nunito;
    font-size: 13px !important;
    font-weight: 800 !important;
}

.orderLogsLabelTable {
    color: black !important;
    font-weight: 800 !important;
    font-size: 10px !important;
    font-family: Nunito !important;
    padding: 0px !important;
}

.closebutton-styling {
    background-color: #EAEAEA;
    width: 120px;
    font-weight: 800 !important;
    text-transform: 'capitalize';
    font-family: 'Nunito';
    font-size: '0.688rem';
    color: '#666666';
    border-radius: '2.5rem';
    height: 35;
    text-align: 'center';
}

.tandc .MuiCollapse-entered {
    padding: 30px !important;
}

.request-button-styling {
    background-color: #EAEAEA;
    width: 120px;
    font-weight: 800 !important;
    text-transform: 'capitalize';
    font-family: 'Nunito';
    font-size: '0.688rem';
    color: blueviolet;
    border-radius: '2.5rem';
    height: 35;
    text-align: 'center';
}

.font-style-color-subheader {
    color: black;
    font-family: Nunito;
    font-size: 10px !important;
    /* font-weight: 700 !important; */
}

.font-customer-type-subheader {
    color: black;
    font-family: Nunito;
    font-size: 11px !important;
    /* font-weight: 700 !important; */
    color: #1A9FE0;
    font-weight: 700 !important;
}

.confirmation-type-subheader {
    color: black;
    font-family: Nunito;
    font-size: 13px !important;
    /* font-weight: 700 !important; */
    color: #1A9FE0;
    font-weight: 700 !important;
    display: contents !important;
}

.delete-warning-icon-customer {
    fill: white !important;
    background-color: #1a9fe0 !important;
    border-radius: 40px !important;
    padding: 4px !important;
    font-size: 40px !important;
}

.confirmation-type-header {
    font-family: Nunito;
    font-size: 0.857rem !important;
    color: #616161;
    font-weight: 700 !important;
}

.headers-font-style {
    color: black;
    font-family: Nunito;
    font-size: 18px !important;
    font-weight: 800 !important;
}

.radiobuttons-subheader {
    color: black;
    font-family: Nunito;
    font-size: 28px !important;
    font-weight: 800 !important;
}

.payment-dialog-content {
    color: black !important;
    font-size: 13px !important;
    font-family: Nunito !important;
    font-weight: 700 !important;
    padding: 10px 15px;
    background-color: rgba(116, 163, 245, 0.3) !important;
}

.special-price-content {
    color: black !important;
    font-size: 13px !important;
    font-family: Nunito !important;
    font-weight: 700 !important;
    padding: 10px 15px;
    background-color: rgba(212, 221, 238, 0.3) !important;

}

.special-price-radiobutton {
    color: black !important;
    font-size: 13px !important;
    font-family: Nunito !important;
    font-weight: 700 !important;
    /* padding: 8px 10px; */
    background-color: rgba(116, 163, 245, 0.3) !important;
}

.dollar-icon-color {
    color: #1A9FE0 !important;
}

.creditLimit-dealerDetails {
    color: #1A9FE0 !important;
    font-weight: 700;
    font-size: 11px;
    font-family: Nunito;
}

.salestoCustomerKam .MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 0 !important;
}

.new-brand-credit-request-button {
    border-radius: 2.5rem !important;
    background-color: #1A9FE0 !important;
    font-weight: 400 !important;
    text-transform: inherit !important;
    font-family: Nunito !important;
    font-size: 0.625rem;
    height: 35px;
}

.color-changes {
    color: #000000;
}

#nationality .MuiPopover-paper {
    height: 350px !important
}

.DateRangePickerInput__withBorder,
.MuiOutlinedInput-notchedOutline {
    border: 2px solid black !important;
}

.font-colorchanges {
    font-size: 0.75rem;
    color: #ff0000;
    font-weight: 800;
}

.font-colorgreenchanges {
    font-size: 0.75rem;
    color: green;
    font-weight: 800;
}

.order-placed-message {
    font-weight: 800;
    font-family: Nunito;
    font-size: 0.90rem;
    color: #071b0b;
}


.Font-changes {
    font-size: 25px;
    font-weight: 800;
    font-size: 1.5rem;
}

.DateInput_input__focused {
    border-bottom: 2px solid #1A9FE0 !important;
}

.MuiIconButton-root:hover {
    background: none;
}

@media only screen and (min-width: 1024px) and (max-width: 1270px) {
    .aed-width {
        width: 50px !important
    }
}

@media only screen and (min-width: 1270px) and (max-width: 2100px) {
    .aed-width {
        width: 70px !important
    }
}


@media only screen and (min-width: 650px) and (max-width: 860px) {
    .aed-width {
        width: 40px !important
    }

    .turnover-width {
        width: 60px !important;
    }
}


@media only screen and (min-width: 860px) and (max-width: 1024px) {
    .aed-width {
        width: 40px !important
    }

    .turnover-width {
        width: 100px !important;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1220px) {
    .aed-width {
        width: 50px !important
    }

    .turnover-width {
        width: 150px !important;
    }
}

@media only screen and (min-width: 1220px) and (max-width: 1300px) {
    .aed-width {
        width: 60px !important
    }

    .turnover-width {
        width: 200px !important;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 2100px) {
    .aed-width {
        width: 70px !important
    }

    .turnover-width {
        width: 280px !important;
    }
}

@media only screen and (min-width: 860px) and (max-width: 1270px) {
    .aed1-width {
        width: 70px !important
    }
}

@media only screen and (min-width: 1270px) and (max-width: 2100px) {
    .aed1-width {
        width: 400px !important
    }
}

.ml-280 {
    margin-left: 280px !important;
}

.ml-70 {
    margin-left: 70px !important;
}


.max-width-80 {
    max-width: 80%;
    height: auto;
}

.cursor-pointer {
    cursor: pointer !important;
}

.border-invalid-parts {
    border-radius: 4px;
    border: 1px solid #bdbdbd !important;
}

.feature-chip {
    border: 1px solid #bdbdbd !important;
    border-radius: 16px !important;
    font-family: Nunito !important;
    font-weight: 400;
}

.font-planfeatures {
    font-family: Nunito !important;
    font-weight: 400;
}

.edit-font {
    font-family: Nunito !important;
}

.fontweight-superadmin-header {
    font-weight: 900;
    font-size: 1.8rem !important;
}

.fontweight-superadmin {
    font-weight: 700;
}

.fontweight-subheader {
    font-size: 12px;
    color: #1a9fe0 !important;
    font-weight: 700;
}

.fontweight-superadmin-supheader {
    font-weight: 600;
}

.fontweight-superadmin-label {
    font-weight: 600 !important;
}

.font-update-superadmin {
    font-size: 1.1rem !important;
    padding: 13px !important;
    font-weight: 1000 !important;

}

.MuiPaper-elevation8 {
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.24)0px 0x 0rem 0rem !important;
    ;
    /* 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) */
}

.servicesImages {
    width: 72px;
    height: 50px
}

.MuiAccordionSummary-root {
    padding: 0px;
}

.MuiAccordionSummary-content {
    margin: 0px;
}

.MuiAccordionSummary-content.Mui-expanded {
    margin: 0px;
}

.left-nav .MuiAccordionSummary-content {
    margin: 0px !important;
}

.left-nav .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px !important;
}

.left-nav .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px !important;
}


.border-accordion {
    border-bottom: 0px !important;
}

.MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(1deg) !important;
}

.MuiAccordionSummary-root.Mui-expanded {
    min-height: 0px !important;
}

.bgchanges-oes {
    opacity: 0.5;
}

.h-100vh {
    height: 100vh !important;
}

.mt-n-65 {
    margin-top: -62px !important;
}

/* .material-icons.orange600{
    color: blue !important;
} */
.maxw150 {
    max-width: 150px;
}

.slider-action .container {
    display: none !important;
}

.videoHomepPage {
    min-height: 70vh;
}

.payment-validation {
    font-family: Nunito !important;
    color: red;
}

.bottom-header-payment {
    color: #444444;
    font-family: Nunito;
    font-size: 10.6px !important;
    font-weight: 800;
}

.padding-credit-payment {
    padding-left: 26.5px !important;
}

.color_black {
    color: black !important;
}

.boxShadowSpecialPrice {
    box-shadow: rgba(0, 0, 0, 0.15)0px 5px 15px 0px !important;
}

.customer-type-popup {
    background-color: #1A9FE0 !important;
}

.customer-type-font {
    color: #f7f3f3 !important;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.customer-header-font {
    color: #f7f3f3;
    opacity: 1;
    font-size: 0.90rem !important;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.customer-sub-header-font {
    color: #1A9FE0;
    font-size: 13px;
    font-family: Nunito !important;
    font-weight: 700 !important;
}

.customer-font {
    color: #f7f3f3;
}

.backcheckbox .checkbox:checked::icon {
    background-color: black !important;
}

.buttonhover :hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
    border-radius: 12px !important;
    position: absolute !important;
    padding: 15px !important;
    color: white !important
}

.boxShadowSpecialPrice {
    box-shadow: rgba(0, 0, 0, 0.15)0px 5px 15px 0px !important;
}

.full-dialog .MuiDialog-paperFullWidth {
    width: calc(100% - 10px) !important;
}

.full-dialog .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 20px) !important;
    overflow: hidden !important;
}

.full-dialog .MuiDialog-paper {
    margin: 10px !important;
}

.collectionPoint {

    padding-left: 35px !important;
}

.uploadFile {
    text-decoration: underline !important;
    text-decoration-color: #1a9fe0 !important;
    color: #1a9fe0 !important;
    cursor: pointer !important;
}

.label-formField {
    width: 100px !important;
    background-color: white !important
}

.margin-companyName {
    margin-left: 31px !important;
}

.margin-poBox {
    margin-left: 106px !important;
}

.margin-telephoneNumber {
    margin-left: 15px !important;
}

.margin-shipping-address {
    margin-left: 14px !important;
}

.margin-top-horizontal {
    margin-top: -1px !important
}

/* .button-secondary .MuiIconButton-colorSecondary:hover {  
    background-color:"rgba(26, 159, 224, 0.04)" !important;
}  */