.backLinkLabel {
    color: #1A9FE0 !important;
    font-family: Nunito !important;
    font-size: 0.813rem;
    font-weight: 700;
}

.totalAed {
    font-size: 0.813rem !important;
    font-family: Nunito !important;
    font-weight: 800 !important;
    margin-top: 2px;
}

.exportExcel {
    color: #1A9FE0 !important;
    font-size: 0.813rem;
    font-weight: 700;
    font-family: Nunito;
}

.orderItems {
    background-color: #ffffff;
    min-height: 20rem;
}

.netHead {
    color: #133F8A;
    font-weight: 900;
    font-size: 0.813rem;
    font-family: Nunito;
    min-width: 70px;
}

.title {
    color: #133F8A !important;
    font-family: Nunito !important;
    font-weight: 800 !important;
    font-size: 0.875rem !important;
}

.subTitle {
    color: #666666;
    font-family: Nunito;
    /* font-size: 0.75rem; */
    font-weight: 700;
}

.subTitleButton {
    pointer-events: none !important;
    background-color: #1A9FE0 !important;
    color: #FFFFFF !important;
    border-radius: 100px !important;
    text-transform: capitalize !important;
    width: 50px !important;
    height: 22px !important;
    padding: 6px 0px !important;
    font-size: 0.688rem !important;
    font-family: Nunito;
}

.MuiButton-root {
    min-width: 0 !important;
}

.subTitleValue {
    color: #666666;
    font-family: Nunito !important;
    /* font-size: 0.6875rem; */
    font-weight: 600;
    float: right;
}

.borderRight {
    border-right: 1px solid rgba(68, 68, 68, 0.09);
}

.bottomBorder {
    border-bottom: 1px solid #dfe1e5;
}

.totalAED {
    color: #000000;
    font-size: 0.813rem;
    font-family: Nunito;
    font-weight: 800;
}
.superseededRow td{
    background-color: #fed4d4 !important;
}

.superseededdot {
    height: 20px;
    width: 40px;
    border: 1px solid black;
    background-color: #fed4d4;
    border-radius: 2px;
    display: inline-block;
}

.superseededdotLabel{
    font-size: 0.75rem;
    color: #666666;
    font-weight: 500;
    font-family: Nunito !important;
}
.subTitleButtonPayment {
    pointer-events: hover !important;
    background-color: #1A9FE0 !important;
    color: #FFFFFF !important;
    border-radius: 100px !important;
    text-transform: capitalize !important;
    width: 95px !important;
    height: 22px !important;
    padding: 6px 0px !important;
    font-size: 0.688rem !important;
    font-family: Nunito;
}
.submit-button-view-order {
    border-radius: 25px !important;
    background-color: #1A9FE0 !important;
    color: #fff !important;
    text-transform: capitalize !important;
    padding-left: 35px !important;
    padding-right: 35px !important;
    height: 2rem;
    font-weight: 700 !important;
    font-family: Nunito !important;
    padding-top:"-100px !important"
}
.upload-lpo{
    margin-right:18px !important
}
.payment-status-padding{
    padding-left: 27px !important;
}
