.rounded-input-specialPrice, .rounded-input-dealer .MuiOutlinedInput-root{
    border-radius: 25px !important;
    /* min-width: 200px !important; */
    
  }
  .inputWidthSpecialPrice{
  min-width: 350px !important;
  }
  .padding-date{
  padding: 12px !important;
  }
  .yellow{
  color:#DBA800 !important;
  font-weight: bold !important;
  }
  .green{
  color:green !important
  }
  .red{
  color: #ec4242  !important;
  font-weight: bold;
  
  }
  