/* .tabSelect .MuiTab-root {
    text-transform: capitalize;
    font-weight: 900 !important;
    font-family: Nunito !important;
    font-size: 13px !important;
} */

.info-sub-title {
    color: #666666;
    font-size: 14px;
    font-family: Nunito !important;
    font-weight: 700;
}

.info-sub-title-value {
    color: #666666;
    
    font-family: Nunito !important;
    font-weight: 600 !important;
}

.download-docs {
    color: #1A9FE0;
    font-weight: 700 !important;
    font-size: 0.90rem !important;
    font-family: Nunito !important;
}

.info-sub-header {
    color: #1A9FE0;
    font-size: 11px;
    font-family: Nunito !important;
    font-weight: 700 !important;
}

.actions {
    padding-top: 1px !important;
}

.cameraIcon {
    color: #1A9FE0;
    font-size: 11px;
    font-weight: 700;
    font-family: Nunito !important;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: -20px;
    margin-left: -17px;
}

.cameraIcon .MuiButton-contained {
    background: none !important;
}

.vertical-divider {
    color: #0000000D;
}

.closeIcon {
    color: #1A9FE0;
    font-size: 11px;
    font-weight: 700;
    font-family: Nunito !important;
    padding-left: 0px;
    padding-right: 0px;
}

.logoSize {
    font-size: 9px;
    color: #999999;
    width: 199px !important;
    font-weight: 600;
    font-family: Nunito !important;
}

.brandLogo {
    border-radius: 100px;
    height: auto;
    width: 80%;
}

.editIcon {
    color: #1A9FE0;
    font-size: 12px;
    font-weight: 700;
    font-family: Nunito !important;
}

.edit {
    font-size: 12px !important;
    color: #1A9FE0;
}

.brandsButton {
    background-color: #666666 !important;
    border-radius: 2.5rem !important;
    color: #FFFFFF !important;
    font-size: 0.813rem !important;
    padding: 1px 16px !important;
    text-transform: capitalize !important;
    font-family: Nunito !important;
}

.brandsButton-content {
    background-color: #666666 !important;
    border-radius: 2.5rem !important;
    color: #FFFFFF !important;
    font-size: 0.813rem !important;
    padding: 1px 16px !important;
    text-transform: capitalize !important;
    font-family: Nunito !important;
}

.uploadedDocs {
    color: #1A9FE0;
    font-size: 13px !important;
    font-family: Nunito !important;
    font-weight: 600;
}

.underline-docs {
    text-decoration: underline;
}

.validityPeriod {
    color: #666666;
    font-size: 10px;
    font-weight: 700;
    padding-left: 1.7rem;
    font-family: Nunito !important;
}

.validityExpiredPeriod {
    color: #FF0000;
    font-size: 10px;
    font-weight: 700;
    padding-left: 1.7rem;
    font-family: Nunito !important;
}

.errorIcon {
    color: #1A9FE0;
    font-size: 20px !important;
}

.cjdrCircle {
    border-radius: 50%;
    width: 22px;
    height: 22px;
    padding: 4px 1px;
    background-color: #666666;
    border: 1px solid #666666;
    color: #FFFFFF;
    font: 9px Nunito;
    font-weight: 700;
    margin-right: 3px;
    margin-top: 0px;
}

.creditCircle {

    border-radius: 14px;
    min-width: 28px;
    text-align: center;
    height: 28px;
    padding: 5px 5px;
    background-color: #666666;
    border: 1px solid #666666;
    color: #FFFFFF;
    font: 13px Nunito;
    font-weight: 700;
}
.creditCircleRed {

    border-radius: 14px;
    min-width: 28px;
    text-align: center;
    height: 28px;
    padding: 5px 5px;
    background-color: #FF0000;
    border: 1px solid #FF0000;
    color: #FFFFFF;
    font: 13px Nunito;
    font-weight: 700;
}

.creditCircleblue {

    border-radius: 14px;
    min-width: 15px;
    text-align: center;
    height: 15px;
    padding: 5px 5px;
    background-color: #1A9FE0 !important;
    border: 1px solid #1A9FE0 !important;
    color: #FFFFFF;
    font: 13px Nunito;
    font-weight: 700;
}

.creditLimit {
    color: #666666 !important;
    font-weight: 700;
    font-size: 11px;
    font-family: Nunito;
}

.belowCreditMessage {
    color: #FF0000 !important;
    font-weight: 700;
    font-size: 10px;
    font-family: Nunito;
}

.belowCreditCircle {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    padding: 5px 5px;
    background-color: #FF0000;
    border: 1px solid #FF0000;
    color: #FFFFFF;
    font: 13px Nunito;
    font-weight: 700;
}

.belowCredit {
    background-color: #FF0000 !important;
}

.addressOrgSetting {
    min-height: 30rem;
}

.creditLimitTitle {
    color: #444444 !important;
    font-weight: 700;
    font-size: 15px;
    font-family: Nunito;
}

/* .shipping-address {
    min-height: 40rem;
} */

.newAdd {
    color: #666666;
    font-family: Nunito;
    font-weight: 600;
    font-size: 14px;
}

.shipAddTitle {
    color: #444444 !important;
    font-weight: 800;
    font-size: 1rem;
    font-family: Nunito;
}

.shipAdd {
    color: #666666 !important;
    font-weight: 600;
    margin: 0;
    padding: 0;   
}

.statusDivision {
    font-weight: 600;
    margin: 0;
    padding: 0;   
}

.shipAddCustomerCode {
    color: #666666 !important;
    font-weight: 800;
    margin: 0;
    padding: 0;
}

.markDefaultIcon {
    color: #1A9FE0;
    font-size: 0.75rem;
    font-weight: 800;
    font-family: Nunito;
}

.defaultButton {
    background-color: #666666 !important;
    border-radius: 2.5rem !important;
    color: #FFFFFF !important;
    font-size: 0.813rem !important;
    padding: 1px 22px !important;
    text-transform: capitalize !important;
    font-family: Nunito !important;
}

.usersListHead {
    color: #FFFFFF;
    font-size: 1.125rem !important;
    font-weight: 700 !important;
}

.add-user-button {
    border-radius: 25px !important;
    background-color: #1A9FE0 !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    height: 2.5rem;
    font-weight: 500 !important;
    font-family: Nunito !important;
    margin-bottom: 10px !important;
}

.add-user-tabs {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 7px 7px;
    background-color: #1A9FE0;
    border: 1px solid #1A9FE0;
    color: #FFFFFF;
    font: 0.613rem Nunito;
    font-weight: 700;
}

.blue-progress-bar {
    height: 16px;
    border-radius: 8px;
    background-color: #eeeeee;
    position: relative;
    color: white;
    font-size: x-small;
    /* font: Nunito; */
    font-weight: 500;
}

.blue-progress-bar::before {
    content: attr(data-label);
    align-items: right;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 30%;
    background-color: #1A9FE0;
    border-radius: 100px;
    padding-top: 1px;
    padding-left: 6px;
}

.red-progress-bar {
    height: 16px;
    border-radius: 8px;
    background-color: #eeeeee;
    position: relative;
    color: white;
    font-size: x-small;
    /* font: Nunito; */
    font-weight: 500;
}

.red-progress-bar .MuiLinearProgress-bar {
    content: attr(data-label);
    align-items: center;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 100%;
    background-color: red !important;
    border-radius: 100px;
    padding-top: 1px;
    padding-left: 6px;
}

.limitExceed{
    font-size: 10px;
    line-height: 16px;
    color: #FF0000;
    align-items: left;
    font-style: Nunito !important;
}
