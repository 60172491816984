.margin-my-quotation {
    margin-left: 90px !important;
}

.padding-left-quotation-list {
    padding-left: 0px !important;
}

.padding-status {
    padding-left: 27px !important;
}

.padding-action {
    padding-left: 27px !important;
}

.margin-text {
    margin-top: 13px !important;
}

.submit-button {
    border-radius: 25px !important;
    background-color: #1A9FE0 !important;
    color: #fff !important;
    text-transform: capitalize !important;
    padding-left: 35px !important;
    padding-right: 35px !important;
    height: 2.4rem;
    font-weight: 700 !important;
    font-family: Nunito !important;
}

.color-text {
    color: #1A9FE0 !important
}

.slider-thumb .MuiSlider-thumb {
    background-color: yellow !important;
    display: none !important;
}

.slider-track .MuiSlider-track {
    background-color: #1A9FE0 !important;
    height: 4.3px;
}

.slider-rail .MuiSlider-rail {
    height: 4.3px !important;
    background-color: #ddd !important;
}

.text-my-quotation {
    margin-top: 10px !important;
}

.text-quotation {
    font-family: Nunito !important;
    font-size: 1rem !important
}

.block-display {
    flex-wrap: wrap !important;
}

.quotation-text {
    font-family: Nunito !important;
}

.action-request {
    color: "white !important" !important;
    background: '#1A9FE0' !important;
    border: "2px solid #1A9FE0" !important;
    border-radius: "8px" !important
}

.my-quotation {
    color: #1a9fe0 !important;

}

.cancel-text {
    border-radius: 10px !important;
    border-color: teal;
    font-size: 15px !important;
    width: 400px;
    height: 120px;
    resize: none !important;
}

.lpo-button {
    color: #FFFFFF;
    height: 35px !important;
    text-align: center !important;
    font-family: Nunito !important;
    font-weight: 800 !important;
    border-radius: 2.5rem !important;
    background-color: #1A9FE0 !important;
    font-weight: 600 !important;
    text-transform: inherit !important;
    height: 2.5rem !important;
    font-size: 11px !important;
}

.lpo-button-disabled {
    pointer-events: none !important;
    opacity: 0.7 !important;
}

.cancel-button-disabled {
    pointer-events: none !important;
    opacity: 0.7 !important;
}

.file {
    margin-top: 3px !important;
    margin-left: 4px !important
}

.inputWidthQuotation {
    min-width: 302px !important;
}
.width-user{
    width:150px !important
}
.submit-margin{
    margin-top:"1px !important"
}
.icon-margin{
    margin-right: -12px !important;
}
