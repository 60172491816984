.borderTextPart textarea:focus {
    border-color: rgba(224, 224, 224, 1);
    outline: none;
  }
  .textAreaCorrectionReject{
  border-radius: 10px !important;
  border-color:  rgba(224, 224, 224, 1) !important ;
  font-size: 15px !important;
  width: 400px;
  height: 120px;
  resize: none;
  }
  .textAreaCorrectionRejectSpecialPrice{
    border-radius: 10px !important;
    border-color:  rgba(224, 224, 224, 1) !important ;
    font-size: 15px !important;
    width: 100% !important;
    height: 100px !important;
    resize: none;
    background: rgba(224, 224, 224, 1) !important;
    position: relative !important;
    }
    .rounded-button-specialPrice{
      background-color: #1a9fe0 !important;
      border-radius: 25px !important;
      text-transform: capitalize !important;
      font-size: 0.75rem !important;
      font-weight: 500 !important;
      font-family: Nunito !important;
      color:#f2f2f2 !important;
  }
  .hoverRejectDialog{
     cursor: pointer !important;
  }
  .cancelIconReject{
    width:40px !important;
    height:40px !important;
    fill:white !important;
    background:red !important;
    border-radius:25px !important;
    padding:6px !important
  }
  .viewJustificationColor{
    color: black !important;
    font-size: 15px !important;
    font-weight: 600 !important;
  }
  .viewJustificationHover{
    cursor: pointer !important;
  }